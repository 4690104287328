<template>
  <v-card>
    <v-card-title>
      <form>
        <v-row align="center">
          <v-col class="d-flex" cols="12" sm="6">
            <v-autocomplete
              label="Pilih Tahun"
              :items="years"
              item-value="years"
              v-model="cari.tahun"
            >
            </v-autocomplete>
          </v-col>
          <v-col class="d-flex" cols="12" sm="4">
            <v-btn @click="showData" class="mr-4" color="primary">
              <v-icon left> mdi-magnify </v-icon>Show
            </v-btn>
          </v-col>
        </v-row>
      </form>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-card class="mt-4 mx-auto" max-width="400">
          <v-sheet
            class="v-sheet--offset mx-auto"
            color="blue"
            elevation="12"
            max-width="calc(100% - 32px)"
            v-for="diterima of diterima"
            :key="diterima.diterima"
          >
            <v-icon color="indigo" class="mr-12" size="64">
              mdi-account
            </v-icon>
            <span>{{ diterima.diterima }}</span>
          </v-sheet>
          <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">
              Diterima
            </div>
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small>
              mdi-clock
            </v-icon>
            <div>
              <span class="caption grey--text font-weight-light">
                {{ new Date() }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="mt-4 mx-auto" max-width="400">
          <v-sheet
            class="v-sheet--offset mx-auto"
            color="blue"
            elevation="12"
            max-width="calc(100% - 32px)"
            v-for="seleksi of seleksi"
            :key="seleksi.jlhseleksi"
          >
            <v-icon color="indigo" class="mr-12" size="64">
              mdi-account
            </v-icon>
            <span>{{ seleksi.jlhseleksi }}</span>
          </v-sheet>
          <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">
              Ikut Seleksi
            </div>
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small>
              mdi-clock
            </v-icon>
            <div>
              <span class="caption grey--text font-weight-light">
                {{ new Date() }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="mt-4 mx-auto" max-width="400">
          <v-sheet
            class="v-sheet--offset mx-auto"
            color="blue"
            elevation="12"
            max-width="calc(100% - 32px)"
            v-for="lulusSeleksi of lulusSeleksi"
            :key="lulusSeleksi.lulusSeleksi"
          >
            <v-icon color="indigo" class="mr-12" size="64">
              mdi-account
            </v-icon>
            <span>{{ lulusSeleksi.lulusSeleksi }}</span>
          </v-sheet>
          <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">
              Lulus Seleksi
            </div>
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small>
              mdi-clock
            </v-icon>
            <div>
              <span class="caption grey--text font-weight-light">
                {{ new Date() }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="DataMaba" :search="search">
          <template v-slot:[`item.Prodi`]="{ item }">
            <v-chip x-small dark>{{
              getParam(item.kode_program_studi).nama_program_studi
            }}</v-chip>
          </template>
          <template v-slot:[`top`]>
            <router-link to="/sync">
              <v-btn
                color="primary"
                dark
                class="mb-2 ml-4"
                :DataMaba="DataMaba"
                :tahun="cari.tahun"
              >
                Sync To Simak
              </v-btn>
            </router-link>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MahasiswaBaru",
  data: () => ({
    cari: {
      tahun: ""
    },
    search: ""
  }),

  computed: {
    years() {
      let year = new Date().getFullYear();
      return Array.from(
        {
          length: year - 2018
        },
        (value, index) => 2019 + index
      );
    },
    ...mapGetters("maba", ["DataMaba", "diterima", "seleksi", "lulusSeleksi"]),
    ...mapGetters("reg", ["prodi"]),
    headers() {
      return [
        {
          text: "Nim",
          value: "nim"
        },
        {
          text: "Nama Peserta",
          value: "nama_mahasiswa"
        },
        {
          text: "Tempat Lahir",
          value: "tempat_lahir"
        },
        {
          text: "Tanggal Lahir",
          value: "tanggal_lahir"
        },
        {
          text: "Kelas",
          value: "kelas"
        },
        {
          text: "Program Studi",
          value: "Prodi"
        },
        {
          text: "Contac",
          value: "no_tlp"
        }
      ];
    }
  },
  methods: {
    ...mapActions("reg", ["loadProdi"]),
    showData() {
      this.$store.dispatch("maba/loadDataMaba", this.cari.tahun).then(() => {
        this.loadProdi();
      });
    },
    getParam(value) {
      return this.prodi.find(t => t.kode_program_studi == value) || "";
    }
  }
};
</script>
<style lang="scss" scoped>
.v-sheet--offset {
  top: -25px;
  position: relative;
  text-align: center;
  font-size: 50px;
}
</style>
